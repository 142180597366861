<template>
  <UILoading v-if="loading"></UILoading>
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <UISelectFixed type="STATS_KLANTEN_OP_BASIS_VAN" class="flex-shrink" v-model="orderBy" />
    <div class="card">
      <h2 class="flex flex-row justify-between">
        <span>Klanten - Aantal</span>
        <UIButtonCompact class="small" v-model="compact" />
      </h2>

      <div class="grid xl:grid-cols-4 gap-4">
        <BamTarget
          v-for="timeframe in Object.keys(statistieken)"
          :key="timeframe"
          :title="capitalize(timeframe)"
        >
          <template v-slot:prefix>
            <ul class="card mb-4 border-t-4 border-dashboard-dark" v-for="item in statistieken[timeframe]" :key="timeframe + item.id">
              <li><strong>Klant: </strong>#{{ item.id }} <ButtonPhoneEmail :email="item.email" /></li>
              <template v-if="!compact">
                <li v-for="option in STATS_KLANTEN_OP_BASIS_VAN()" :key="option.value">
                  <strong>{{ option.label }}: </strong> {{ item[option.value] % 1 > 0 ? formatPrice(item[option.value]) : item[option.value] }}
                </li>
              </template>
            </ul>
          </template>
        </BamTarget>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { capitalize } from '@/functions/formatText'
import { STATS_KLANTEN_OP_BASIS_VAN } from '@/constants/OPTIONS'
import { formatPrice } from '@/functions/formatNumber'

import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

import BamTarget from '@/components/Charts/BamTarget.vue'
import UILoading from '@/components/UI/Loading.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import ButtonPhoneEmail from '@/components/UI/Button/PhoneEmail'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'

const props = defineProps({
  filters: Object,
  renderKey: [String, Number],
})

const compact = ref(false)

useRenderKey(props.renderKey)

const orderBy = ref(STATS_KLANTEN_OP_BASIS_VAN()[0].value)
const apiData = computed(() => Object.assign({}, props.filters, { orderBy: orderBy.value }))
const { data, loading } = useGetApi('STATS_KLANTEN_TOP', apiData, { watch: true })
const statistieken = computed(() => data.value?.statistieken || {})
</script>
